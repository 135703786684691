import React, { Component } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { color } from '../../../utils/style';
import IcomoonIcon from '../IcomoonIcon';

const customStyles = {
  indicatorsContainer: (base) => ({
    ...base,
    display: 'none',
  }),
  option: (provided, state) => ({
    ...provided,
    color: state.isFocused ? '#fff' :
      state.isSelected ? '#fff' :
        state.isDisabled ? '#888' : '#ccc !important',
    background: state.isSelected ? '#216ba5' :
      state.isFocused ? '#424242' : '#060104',
    padding: '5px',
    margin: '0 20px',
    width: 'calc(100% - 40px)',
    cursor: state.isSelected ? 'default' : 'pointer',
    borderTop: state.isFocused ? '1px solid transparent' : '1px solid rgba(255,255,255,0.1)',
  }),
  control: (provided, state) => ({
    borderRadius: state.menuIsOpen ? '24px 24px 0 0' : '24px',
    backgroundColor: state.menuIsOpen ? '#060104' : 'rgba(255,255,255,0.05)',
    border: '1px solid transparent',
    padding: '5px 20px 10px 12px',
    cursor: 'pointer',
    height: '46px',
  }),
  menu: () => ({
    border: 'none',
    borderTop: 'none',
    borderRadius: '0 0 24px 24px',
    padding: '0 0 20px',
    background: '#060104',
    maxHeight: '50%',
    zIndex: 10,
  }),
  singleValue: () => ({
    width: 'calc(100% - 14px)',
    marginTop: '-2px'
  }),
  placeholder: () => ({
    color: 'white',
    opacity: 0.4,
    marginTop: '-2px'
  })
};

const theme = theme => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: '#216ba5',
    primary: 'rgba(255,255,255,0.05)',
    primary75: '#216ba5',
    primary50: '#216ba5',
    neutral0: '#060104',
    neutra80: '#FFFFFF',
    borderRadius: 25
  }
});

class SearchableSelect extends Component {
  handleChange = value => {
    // this is going to call setFieldValue and manually update values.topcis
    this.props.onChange(this.props.name, value);
  };

  handleBlur = () => {
    // this is going to call setFieldTouched and manually update touched.topcis
    this.props.onBlur(this.props.name, true);
  };


  render() {
    return (
      <div className="labelField">
        <label htmlFor={this.props.name}>{this.props.label}</label>
        <div className="fieldWrap iconField selectField">
          <IcomoonIcon icon="arrow-down-simple" color={color.white} size={18}/>
          {!!this.props.error &&
          this.props.touched && (
            <div className={this.props.errorClass}>{this.props.error}</div>
          )}
          <Select styles={customStyles} theme={theme}
                  components={{ IndicatorSeparator: null }}
            name={this.props.name}
            id={this.props.name}
            options={this.props.options}
            onChange={this.handleChange}
            onBlur={this.handleBlur}
            value={this.props.value}
            placeholder={this.props.placeholder}
            isSearchable={true}
            classNamePrefix="searchable-select"
            className="searchable-select"
          />
        </div>
      </div>
    );
  }
}

export default SearchableSelect;

SearchableSelect.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  errorClass: PropTypes.string,
  placeholder: PropTypes.string,
  options: PropTypes.array
};