import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Formik, Field, Form } from 'formik';
import { color, formStyles } from '../../../utils/style';
import * as Yup from 'yup';
import IcomoonIcon from '../../shared/IcomoonIcon';

const Wrapper = styled('div')`
  ${formStyles}
  .button {
    width: 100%;
  }
  .submitting {
    .labelField {
      opacity: 0.5;
    }
  }
  .form-inputs {
    max-width: 350px;
    margin: 0 auto;
    .errorMsg {
      background: ${color.red};
      color: ${color.white};
      padding: 10px 20px;
      border-radius: 5px;
      margin-bottom: 20px;
      text-align: center;
      display: none;
      &.visible {
        display: block;
      }
    }
    .visible ~ .labelField {
      opacity: 1;
    }
  }
`;

const userApiUrl = process.env.DEPOSIT_API_STEP1_URL;
const userApi2Url = process.env.DEPOSIT_API2_STEP1_URL;
const userAuthUrl = process.env.WITHDRAW_API2_AUTH_URL;

export default class DepositUserData extends Component {

  showErrorMsg = (msg) => {
    const errorMsg = document.getElementById('userDataErrorMsg');
    errorMsg.innerText = msg;
    errorMsg.classList.add('visible');
  };

  submitFormHandler = (values, formApi) => {
    let body = JSON.stringify(values);
    console.log('Data going to auth: ');
    console.log(values);
    fetch(userAuthUrl, {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest'
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
      body: body
    }).then(response => {
      const status = response.status;
      const data = response.json();
      return data.then((res) => {
        return {status, json: res}
      })
    }).then(({status, json}) => {
      if (status !== 200) {
        this.showErrorMsg('ขออภัยชื่อผู้ใช้หรือบัญชีธนาคารนี้ไม่ถูกต้องหรือไม่มีอยู่ กรุณาใส่รายละเอียดที่ถูกต้อง');
        formApi.setSubmitting(false);
      } else {
        this.props.setUserName(values.userName, json.token);
        fetch(userApi2Url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + json.token,
          },
        }).then(response => {
          const status = response.status;
          const data = response.json();
          return data.then((result) => {
            return {status, json: result}
          }).then(({status, json}) => {
            if (status === 200) {
              this.props.switchView('paymentData', json);
            } else if (status === 500) {
              this.showErrorMsg('มีบางอย่างผิดพลาด โปรดลองอีกครั้งในภายหลังหรือติดต่อคอลเซ็นเตอร์ของเรา');
              formApi.setSubmitting(false);
            } else if (status === 401) {
              this.showErrorMsg('ขออภัยชื่อผู้ใช้หรือบัญชีธนาคารนี้ไม่ถูกต้องหรือไม่มีอยู่ กรุณาใส่รายละเอียดที่ถูกต้อง');
              formApi.setSubmitting(false);
            }
          });
        });


      }
    })
      .catch(function (error) {
        console.log('Send Request failed', error);
      });



};

  render() {
    const userName = this.props.userName;
    const initialValues = {
      userName: userName,
      accountNo: '',
    };
    return (
      <Wrapper>
        <Formik
          initialValues={initialValues}
          onSubmit={this.submitFormHandler}
          validate={values => {
            let errors = {};
            if (values.userName && errors.userName) {
              errors.username = 'Alphanumeric please';
            }
            return errors;
          }}
          validationSchema={Yup.object().shape({
            userName: Yup.string().matches(/^[a-z0-9]+$/i, 'กรอกได้เฉพาะตัวอักษรและตัวเลขเท่านั้น').required('จำเป็นต้องใช้'),
            accountNo: Yup.string().matches(/^[-0-9]+$/i, '0-9 และขีดคั่นเท่านั้น').typeError('จำเป็นต้องใช้')
              .min(0, 'positive, please').required('จำเป็นต้องใช้'),
          })}

          render={({ errors, status, touched, isSubmitting, isValid, values }) => (
            <Form id="fetchUserDataForm" className={isSubmitting ? "submitting" : ""}>
              <div className="form-inputs">
                <div id="userDataErrorMsg" className="errorMsg">Failed sending data, please try again</div>
                <div className="labelField">
                  <label htmlFor="deposit-username">ยูสเซอร์เนม</label>
                  <div className="fieldWrap iconField">
                    <IcomoonIcon icon="user" color={color.white} size={18}/>
                    {errors.userName && touched.userName && <div className="error-new">{errors.userName}</div>}
                    <Field type="text" name="userName" placeholder="ยูสเซอร์เนม" id="deposit-username"
                           value={values.userName || ''}/>
                  </div>
                </div>

                <div className="labelField">
                  <label htmlFor="deposit-bank-acc-num">เลขที่บัญชี</label>
                  <div className="fieldWrap iconField">
                    {errors.accountNo && touched.accountNo && <div className="error-new">{errors.accountNo}</div>}
                    <Field type="text" name="accountNo" maxLength="20"
                           placeholder="111-2-33333-4" id="deposit-bank-acc-num"
                           value={values.accountNo || ''}/>
                  </div>
                </div>

                <div className="button-wrapper">
                  {status && status.msg && <div>{status.msg}</div>}
                  <button aria-label="เข้าสู่ระบบ" className="button gradient-button" type="submit"
                          disabled={(!isValid || isSubmitting)}>
                    <span>ต่อไป</span>
                  </button>
                </div>
              </div>
            </Form>
          )}
        />
      </Wrapper>
    );
  }
}

DepositUserData.propTypes = {
  switchView: PropTypes.func,
  setUserName: PropTypes.func,
  userName: PropTypes.string
};