import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Formik, Field, Form } from 'formik';
import { color, formStyles, breakpoints } from '../../../utils/style';
import * as Yup from 'yup';
import IcomoonIcon from '../../shared/IcomoonIcon';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
//import SelectOptions from '../../shared/forms/helpers';
import SearchableSelect from '../../shared/forms/SearchableSelect';


const Wrapper = styled('div')`
  ${formStyles}
  .react-datepicker-wrapper {
    width: 100%;
  }
  .fileUploadField {
    position: relative;
    height: 100%;
    input { display: none; }
    img {
      position: absolute;
      right: 20px;
      top: 10px;
    }
    .loading {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      padding: 10px;
      text-align: center;
    }
  }
  
  .fileUploadLabel {
    display: block;
    height: 29%;
    border-radius: 24px;
    background: ${color.inputBg};
    border: 2px dashed rgba(255, 255, 255, 0.33);
    padding: 25px 20px;
    font-size: 16px;
    text-align: center;
    svg {
      margin-right: 10px;
      opacity: 0.5;
    }
    &:hover, &:active {
      border-color: ${color.white};
      color: ${color.white};
      svg {
        opacity: 1;
      }
    }
  }
  .columns-wrapper {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    .col-3 {
      width: 30%;
    }
    .errorMsg {
      background: ${color.red};
      color: ${color.white};
      padding: 10px 20px;
      border-radius: 5px;
      margin-bottom: 20px;
      text-align: center;
      width: 100%;
      display: none;
      &.visible {
        display: block;
      }
    }
  }
  
  .successMsg {
    &.visible {
      display: block;
      & ~ .col-3, & ~ .button-wrapper {
        display: none;
      }
    }
  }
  .successMsgText {
    p {
      margin-top: 30px;
      font-size: 2rem; 
    }
    .small {
      font-size: 16px;
      margin-top: 20px;
    }
  }
  
  .searchable-select__input {
    input {
      background: none !important;
    }
  }
  
  .searchable-select__single-value {
    color:${color.white};
  }
  
  .error-new + .searchable-select .searchable-select__control {
    border: 1px solid ${color.red};
  }
  
  .minimal-custom-checkbox {
    margin-top: 24px;
  }
  
  
  .button-wrapper {
    position: relative;
    width: 100%;
    padding-top: 50px;
    text-align: center;
  }
  .back-button {
    border-color: transparent !important;
    position: absolute;
    top: 50px;
    left: 0;
    span {margin-left: 7px;}
    &:hover, &:active {
      background-color: transparent !important;
      border-color: ${color.white} !important;
      span {
        color: ${color.white} !important;
      }
    }
  }
  
  .secure-label {
    padding: 15px 30px 0;
    font-size: 12px;
    color: ${color.labelColor};
  }
  
  .gradient-button {
    min-width: 30%;
    margin-right: 0;
  }
  
  @media (max-width: ${breakpoints.sm}) {
    .columns-wrapper {
      .col-3 {
        width: 100%;
      }
    }
    .button-wrapper {
      text-align: right;
      padding-top: 0;
    }
    .back-button {
      top: 0;
    }
    .fileUploadField {
      height: auto;
    }
    .fileUploadLabel {
      height: auto;
      margin-top: 14px;
      padding: 20px;
      margin-bottom: 30px;
    }
    .successMsg {
      padding: 0 30px;
    }
    .successMsgText {
      p {
        font-size: 1rem;
      }
      .small {
        font-size: 13px;
        margin-top: 15px;
      }
    }
  }
`;

const Checkbox = ({
   field: { name, value, onChange, onBlur },
   form: { errors, touched, setFieldValue },
   id, label, ...props }) => {
  return (
    <div className="minimal-custom-checkbox">
      <input
        name={name}
        id={id}
        type="checkbox"
        value={value}
        checked={value}
        onChange={onChange}
        onBlur={onBlur}
      />
      <label htmlFor={id}>{label}</label>
    </div>
  );
};

const fileToBase64 = file => {
  return new Promise(resolve => {
    let reader = new FileReader();
    if (file && file.type.match('image.*')) {
      reader.onloadend = () => {
        //console.log('reader.result', reader.result);
        resolve(reader.result);
      };
      reader.readAsDataURL(file);
    }
  });
};
class FileUpload extends React.Component {
  state = {
    loading: false,
    thumb: undefined,
  };

  static getDerivedStateFromProps(nextProps, prevState){
    if(nextProps.file!==prevState.file){
      return { thumb: nextProps.file};
    }
    else return null;
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevProps.file!==this.props.file){
      //Perform some operation here
      this.setState({loading: false, thumb: this.props.file});
    }
  }

  /*componentWillReceiveProps(nextProps) {
    if (!nextProps.file) {
      return;
    }
    this.setState({ loading: false, thumb: nextProps.file });
  }*/

  render() {
    const { file } = this.props;
    const { loading, thumb } = this.state;
    if (!file) {
      return null;
    }
    if (loading) {
      return <p>loading...</p>;
    }
    return (<img src={thumb}
                 alt={'uploaded file'}
                 className="img-thumbnail mt-2"
                 height={50}
                 width={50}/>);
  }
}

//const paymentApiUrl = process.env.DEPOSIT_API_STEP2_URL;
const paymentApi2Url = process.env.DEPOSIT_API2_STEP2_URL;


export default class DepositPaymentData extends Component {

  showSuccessMsg = () => {
    this.hideMsg();
    const successMsg = document.getElementById('successMsgBox');
    successMsg.classList.add('visible');
  };

  hideMsg = () => {
    const successMsg = document.getElementById('successMsgBox');
    successMsg.classList.remove('visible');
    const errorMsg = document.getElementById('errorMsg');
    errorMsg.classList.remove('visible');
  };

  showErrorMsg = (msg) => {
    const errorMsg = document.getElementById('errorMsg');
    errorMsg.innerText = msg;
    errorMsg.classList.add('visible');
  };

  submitFormHandler = (values, formApi) => {
    let valuesObject = Object.assign({}, values);
    const time = new Date(values.transactiontime);
    const timeHours = time.getHours();
    const timeMinutes = time.getMinutes();
    const timeSeconds = time.getSeconds();
    let date = new Date(values.transactiondate);
    date.setHours(timeHours, timeMinutes, timeSeconds);
    const tzOffset = (new Date()).getTimezoneOffset() * 60000;
    valuesObject.transactiondate = new Date(date - tzOffset).toISOString();
    valuesObject.base64Image = values.base64Image.replace(/^.*,/, '');
    valuesObject.bankaccountId = values.bankaccountId.value + '';
    valuesObject.amount = +values.amount;
    valuesObject.remarks = values.remarks ? 'YES bonus' : 'NO bonus';
    if (values.promocode) {
      if (values.remarks) {
        valuesObject.remarks = valuesObject.remarks + '. PROMO CODE: ' + values.promocode;
      } else {
        valuesObject.remarks = 'PROMO CODE: ' + values.promocode;
      }
    }
    delete valuesObject['transactiontime'];
    delete valuesObject['promocode'];
    console.log('Data going to: https://test-webblazorw.azurewebsites.net/api/v2/Deposits/Deposit: ');
    console.log(valuesObject);

    let body = JSON.stringify(valuesObject);

    this.hideMsg();

    fetch(paymentApi2Url, {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.props.token,
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
      body: body
    }).then(response => {
      const status = response.status;
      const data = response.json();
      return data.then((res) => {
        return {status, json: res}
      })
    }).then(({status, json}) => {

      if (status === 401) {
        this.showErrorMsg('ขออภัยชื่อผู้ใช้หรือบัญชีธนาคารนี้ไม่ถูกต้องหรือไม่มีอยู่ กรุณาใส่รายละเอียดที่ถูกต้อง');
        formApi.setSubmitting(false);
      } else if (status === 400) {
        this.showErrorMsg('Bad Request.');
        formApi.setSubmitting(false);
      } else if (status === 500) {
        /*  Start Ugly hack to cover our asses */
        if (json.type.includes('NotifyAllTransactionCreated')) {
          this.showSuccessMsg();
          formApi.setSubmitting(false);
        } else {
          this.showErrorMsg('บัญชีมีการทำธุรกรรมการฝากเงินที่ดำเนินการอยู่ โปรดรอสักครู่ เราดำเนินการธุรกรรมของคุณ หากการฝากเงินของคุณไม่ได้รับเครดิตภายใน 5 นาทีโปรดติดต่อคอลเซ็นเตอร์ของเรา');
          formApi.setSubmitting(false);
        }
        /* End Ugly hack to cover our asses */
      } else if (status === 200) {
        this.showSuccessMsg();
        formApi.setSubmitting(false);
      }
    })
      .catch(function (error) {
        console.log(error);
      });



    //this.props.switchView('userData', {});
  };

  render() {
    const formData = this.props.formData;
    console.log('Auth response:');
    console.log(formData);
    const codesArray = this.props.codes.split('|');
    const codesString = '^' + codesArray.join('$|^') + '$';
    const codes = this.props.codes ? new RegExp(codesString, "i") : 'no-coupon-now';
    const initialValues = {
      amount: 0,
      depositChannelId: '',
      bankaccountId: '',
      customerBankAccountId: '',
      remarks: '',
      promocode: '',
      phone: '000-000-000',
      base64Image: '',
      transactiondate: new Date(),
      transactiontime: new Date(),
    };

    const defaultOption = <option key="default" defaultValue={true}>เลือกที่นี่</option>;
    const depositChannelIdOptionArray = formData.depositChannelsDropDown.results.map(i =>
      <option key={i.id} value={i.id}> {i.text} </option>
    );
    const depositChannelId = [defaultOption, ...depositChannelIdOptionArray];

    const customerBankAccounts = formData.customerBankAccountsDropDown.results.map(i =>
      <option key={i.id} value={i.id}> {i.text} </option>
    );
    const customBankAccountOptions = [defaultOption, ...customerBankAccounts];

    let customerBankAccountsArr = [];
    formData.bankAccountsDropDown.results.map(element =>
      customerBankAccountsArr.push({value: element.id, label: element.text})
    );

    return (
      <Wrapper>
        <Formik
          initialValues={initialValues}
          onSubmit={this.submitFormHandler}
          validationSchema={Yup.object().shape({
            amount: Yup.number().typeError('จำนวนที่ต้องการ')
              .min(1, 'Enter 1 or more').required('จำนวนที่ต้องการ'),
            depositChannelId: Yup.string().required('จำเป็นต้องใช้'),
            customerBankAccountId: Yup.string().required('จำเป็นต้องใช้'),
            bankaccountId: Yup.string().required('จำเป็นต้องใช้'),
            transactiondate: Yup.date().required('จำเป็นต้องใช้'),
            transactiontime: Yup.date().required('จำเป็นต้องใช้'),
            promocode: Yup.string().matches(codes, 'ไม่มีรหัสดังกล่าว'),
            base64Image: Yup.string().required('จำเป็นต้องใช้'),
          })}

          render={({ errors, status, touched, isSubmitting, isValid, values, setFieldValue, setFieldTouched }) => (
            <Form id="sendUserPaymentForm">
              <div className="columns-wrapper">
                <div id="errorMsg" className="errorMsg">There has been an error!</div>
                <div id="successMsgBox" className="successMsg">
                  <IcomoonIcon icon="check" color="#ffcdcd" size={46}/>
                  <div className="successMsgText">
                    <p>ส่งแบบฟอร์มการฝากของคุณสำเร็จแล้ว โปรดรอขณะที่เราดำเนินการธุรกรรมของคุณ หากการฝากเงินของคุณไม่ได้รับเครดิตภายใน 5 นาทีโปรดติดต่อคอลเซ็นเตอร์ของเรา</p>
                    <p className="small">
                      แอด <a target="_blank" href="https://lin.ee/nEuGE0U">LineID @LNWASIA</a> เพื่อไม่พลาดการติดต่อกับเรา
                    </p>
                  </div>
                </div>

                <div className="col-3">
                  <div className="labelField">
                    <label htmlFor="deposit-amount">จำนวนเงินที่ต้องการฝาก</label>
                    <div className="fieldWrap iconField">
                      <span className="icon icon-span">บาท</span>
                      {errors.amount && touched.amount && <div className="error-new">{errors.amount}</div>}
                      <Field type="text" name="amount" placeholder="0.00" id="deposit-amount"
                             value={values.amount || ''}/>
                    </div>
                  </div>
                  <div className="labelField">
                    <label htmlFor="deposit-channel">ช่องทางการฝาก</label>
                    <div className="fieldWrap iconField">
                      <IcomoonIcon icon="arrow-down-simple" color={color.white} size={18}/>
                      {errors.depositChannelId && touched.depositChannelId &&
                        <div className="error-new">{errors.depositChannelId}</div>
                      }
                      <Field name="depositChannelId" component="select" placeholder="Deposit Channel" id="deposit-channel">
                        {depositChannelId}
                      </Field>
                    </div>
                  </div>
                  <div className="labelField">
                    <label htmlFor="deposit-date">วันที่</label>
                    <div className="fieldWrap iconField">
                      <IcomoonIcon icon="calendar" color={color.white} size={18}/>
                      {errors.transactiondate && touched.transactiondate &&
                      <div className="error-new">{errors.transactiondate}</div>
                      }
                      <DatePicker
                        selected={values.transactiondate}
                        dateFormat="MMMM d, yyyy"
                        className="form-control"
                        name="transactiondate"
                        onChange={date => setFieldValue('transactiondate', date)}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-3">

                  <div className="labelField">
                    <label htmlFor="deposit-time">เวลา</label>
                    <div className="fieldWrap iconField">
                      <IcomoonIcon icon="clock" color={color.white} size={18}/>
                      {errors.transactiontime && touched.transactiontime &&
                      <div className="error-new">{errors.transactiontime}</div>
                      }
                      <DatePicker
                        selected={values.transactiontime}
                        className="form-control"
                        name="transactiontime"
                        onChange={date => setFieldValue('transactiontime', date)}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        dateFormat="HH.mm"
                        timeFormat="HH.mm"
                      />
                    </div>
                  </div>

                  <div className="labelField">
                    <label htmlFor="deposit-account">
                      ธนาคารและเลขบัญชี 4 หลักสุดท้าย
                    </label>
                    <div className="fieldWrap iconField">
                      <IcomoonIcon icon="arrow-down-simple" color={color.white} size={18}/>
                      {errors.customerBankAccountId && touched.customerBankAccountId &&
                        <div className="error-new">{errors.customerBankAccountId}</div>
                      }
                      <Field name="customerBankAccountId" component="select"
                             placeholder="Player Bank Account" id="deposit-account">
                        {customBankAccountOptions}
                      </Field>
                    </div>
                  </div>

                  <SearchableSelect label="ธนาคารที่ได้ทำการฝากเข้ามา (ของlnwasia)" value={values.bankaccountId}
                     name="bankaccountId" errorClass="error-new"
                     onChange={setFieldValue} onBlur={setFieldTouched}
                     error={errors.bankaccountId} placeholder="กรอกเลขสี่หลักสุดท้าย"
                     touched={touched.bankaccountId} options={customerBankAccountsArr}
                  />

                </div>

                <div className="col-3">

                  <div className="labelField">
                    <label htmlFor="deposit-promocode">ใส่รหัสโปรโมชั่น</label>
                    <div className="fieldWrap iconField">
                      <IcomoonIcon icon="gift" color={color.white} size={18}/>
                      {errors.promocode && touched.promocode && <div className="error-new">{errors.promocode}</div>}
                      <Field type="text" name="promocode" placeholder="ใส่รหัสโปรโมชั่น" id="deposit-promocode"
                             value={values.promocode || ''}/>
                    </div>
                  </div>

                  <div className="fieldWrap">
                    <Field
                      component={Checkbox}
                      name="remarks"
                      id="deposit-remarks"
                      label="ฉันต้องการที่จะรับโบนัส"
                      value={values.remarks ? values.remarks : false}/>
                  </div>

                  <div className="labelField fileUploadField">
                    <label htmlFor="base64Image" className="fileUploadLabel">
                      <IcomoonIcon icon="upload" color={color.white} size={28}/>
                      อัปโหลดสลิปที่ทำรายการ
                    </label>
                    {errors.base64Image && touched.base64Image && <div className="error">{errors.base64Image}</div>}
                    <input id="base64Image" name="base64Image" accept="image/*" type="file" onChange={(event) => {
                      fileToBase64(event.currentTarget.files[0]).then((result) => setFieldValue("base64Image",result))
                    }} className="form-control"/>
                    <FileUpload file={values.base64Image} onChange={setFieldValue} name='base64Image'/>
                  </div>
                </div>

                <div className="button-wrapper">
                  <button aria-label="Back" className="button transparent-button back-button" onClick={this.props.switchVisual}>
                    <IcomoonIcon icon="arrow-left-simple" color={color.white} size={18}/>
                    <span>ย้อนกลับ</span>
                  </button>
                  {status && status.msg && <div>{status.msg}</div>}
                  <button aria-label="เข้าสู่ระบบ" className="button gradient-button" type="submit"
                          disabled={(!isValid || isSubmitting)}>
                    <span>ส่งข้อมูล</span>
                  </button>
                  <div className="secure-label iconLabel">
                    <IcomoonIcon icon="lock" color={color.labelColor} size={12}/>
                    Secure pay
                  </div>
                </div>
              </div>
            </Form>
          )}
        />
      </Wrapper>

    );
  }
}

DepositPaymentData.propTypes = {
  switchView: PropTypes.func,
  switchVisual: PropTypes.func,
  formData: PropTypes.object
};