import React from 'react';
import { graphql, Link } from 'gatsby';
import Layout from '../../components/shared/layout';
import SEO from '../../components/SEO/SEO';
import Img from 'gatsby-image';
import styled from '@emotion/styled';
import {
  wrapper1200,
  featuredWrapper,
  color,
  breakpoints,
  font,
  ContentStyles,
  ContentStylesExt
} from '../../utils/style';
import Bell from '../../icons/svg/Bell';
import ContentWithFluidImages from '../../components/shared/ContentWithFluidImages';
import Breadcrumbs from '../../components/shared/Breadcrumbs';
//import DepositForm from '../../components/Auth/Form/DepositForm';
import DepositParent from '../../components/Content/Deposit/DepositParent';

const FeaturedWrapper = styled('div')`
  ${featuredWrapper}
  position: relative;
  margin: 15px 0;
  .gatsby-image-wrapper {
    border-radius: 24px;
  }
  @media (max-width: ${breakpoints.md}) {
    .gatsby-image-wrapper {
      border-radius: 16px;
    }
  }

`;

const NoFeaturedWrapperReg = styled('div')`
  border-radius: 16px;
  padding: 50px 40px 30px;
  background: ${color.black};
  @media (max-width: ${breakpoints.md}) {
    padding: 20px 0;
    background: none;
  }
`;

const WrapperDesktop = styled('div')`
  ${wrapper1200}
`;

const ContentWrapper = styled('div')`
  ${ContentStyles}
  ${ContentStylesExt}
  .content-casino {
    figure {
      flex-basis: 25%;
    }
    dl {
      flex-basis: 74%;
    }
  }
  @media (max-width: ${breakpoints.sm}) {
    .content-casino {
      figure, dl {
        flex-basis: 100%;
      }
    }
  }
`;

const Bg = styled('div')`
  border-top: 1px solid ${color.transparentWhite1};
  padding:15px 0 5px;
`;
const RelatedWrapper = styled('div')`
  padding: 30px 0;
  margin-top: 10px;
  text-align: center;
  h3 {
    font-size:1.6rem;
    margin-top:0;
  }
  ul {
    list-style: none;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
  }
  li {
    width: 23%;
    text-align: left;
  }

  .recent-name {
    font-size: 1rem;
    font-weight: ${font.headingWeight};
    margin: 20px 0 10px;
  }
  
  .recent-excerpt {
    font-size: 14px;
    line-height: 20px;
    max-height: 140px;
    overflow-y: hidden;
  }
    
  @media (max-width: ${breakpoints.md}) {
    ul {
      margin: 10px 0;
    }
    li {
      width:48%;
    }
  }
  @media (max-width: ${breakpoints.xs}) {
    li {
      width: 100%;
    }
  }
`;



const PageTemplate = ({ data, pageContext }) => {
  const currentPage = data.wordpressPage;
  const featuredMedia = currentPage.featured_media;
  const metaTitle = (currentPage.yoast_meta && currentPage.yoast_meta.yoast_wpseo_title)
    ? currentPage.yoast_meta.yoast_wpseo_title : currentPage.title + "- " + process.env.OWNER;
  const metaDesc = (currentPage.yoast_meta && currentPage.yoast_meta.yoast_wpseo_metadesc)
    ? currentPage.yoast_meta.yoast_wpseo_metadesc : '';
  const relatedPosts = data.allWordpressPost;
  const placeholder = data.placeholder;
  const relatedIds = pageContext.related;
  const media = data.allWordpressWpMedia.edges;
  const breadcrumbs = pageContext.breadcrumbs;
  const page_title = pageContext.page_title;
  const socialImage = currentPage.featured_media?.localFile?.publicURL;

  return (
    <Layout>
      <SEO
        title={metaTitle}
        description={metaDesc}
        pathname={currentPage.path}
        breadcrumbs={breadcrumbs}
        socialImage={socialImage}
      />
      <WrapperDesktop>

        {featuredMedia
        && featuredMedia.localFile
        && featuredMedia.localFile.childImageSharp
        && featuredMedia.localFile.childImageSharp.fluid ?
          <div>
            <FeaturedWrapper>
              <Breadcrumbs breadcrumbs={breadcrumbs} page_title={page_title}/>
              <Img fluid={featuredMedia.localFile.childImageSharp.fluid}
                   alt={(currentPage.featured_media.alt_text) ? currentPage.featured_media.alt_text : currentPage.title}/>
              <h1 dangerouslySetInnerHTML={{ __html: currentPage.title }}/>
            </FeaturedWrapper>
            <ContentWrapper>
              <div className="top-text-block" dangerouslySetInnerHTML={{ __html: currentPage.acf.top_page_text }}/>
            </ContentWrapper>
            <NoFeaturedWrapperReg>
              <DepositParent codes={currentPage.acf.deposit_coupons}/>
            </NoFeaturedWrapperReg>
          </div>
          : <div>
            <h1 dangerouslySetInnerHTML={{ __html: currentPage.title }}/>
            <NoFeaturedWrapperReg>
              <DepositParent codes={currentPage.acf.deposit_coupons}/>
            </NoFeaturedWrapperReg>
          </div>}

        <ContentWrapper>
          {process.env.WP_REPLACE_CONTENT_IMAGES === '1' ?
            ContentWithFluidImages(currentPage.content, media) :
            <div dangerouslySetInnerHTML={{ __html: currentPage.content }}/>}
        </ContentWrapper>


        {relatedIds.length > 0 &&
        <Bg>
          <RelatedWrapper>
            <h3 className='h3 with-icon'>
              <Bell/>
              <span>อัพเดท บทความ ข่าวสารจาก GClub</span>
            </h3>
            <ul>
              {relatedPosts.edges.map((item, index) =>
                <li key={item.node.slug + index}>
                  <Link to={"/" + item.node.slug + "/"}>
                    {(item.node.featured_media && item.node.featured_media.localFile
                      && item.node.featured_media.localFile.childImageSharp && item.node.featured_media.alt_text)
                      ? <div className="img-wrap">
                        <Img fluid={item.node.featured_media.localFile.childImageSharp.fluid}
                             alt={item.node.featured_media.alt_text}/>
                      </div>
                      : <div className="img-wrap">
                        <Img fluid={placeholder.childImageSharp.fluid} alt={item.node.label}/>
                      </div>
                    }
                    <div className='recent-name' dangerouslySetInnerHTML={{ __html: item.node.title }}/>
                    <div className='recent-excerpt' dangerouslySetInnerHTML={{ __html: item.node.excerpt }}/>
                  </Link>
                </li>
              )}
            </ul>
          </RelatedWrapper>
        </Bg>}
      </WrapperDesktop>
    </Layout>
  );
};

export default PageTemplate;

export const pageQuery = graphql`
  query($id: String!, $related: [Int], $contentImages: [Int]) {
    wordpressPage(id: { eq: $id }) {
      wordpress_id
      title
      content
      date(formatString: "MMMM DD, YYYY")
      path
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
      }
      acf {
        page_template
        top_page_text
        deposit_coupons
      }
      featured_media {
        alt_text
        localFile {
        publicURL
          childImageSharp {
            fluid(maxWidth: 1400) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    allWordpressPost(filter: {
      wordpress_id: {in: $related}, 
      acf: {post_template: {ne: "service_post_carousel"}}
    }, limit:4) {
      edges {
        node {
          slug
          title
          excerpt
          wordpress_id
          featured_media {
            alt_text
            localFile {
              childImageSharp {
                fluid(maxWidth: 350) {
                ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
    placeholder: file(relativePath: { eq: "placeholder.png" }) {
      childImageSharp {
        fluid(maxWidth: 350) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    allWordpressWpMedia(filter: {wordpress_id: {in: $contentImages}}) {
      edges {
        node {
          wordpress_id
          alt_text
          source_url
          localFile {
            publicURL
            childImageSharp {
              fluid(maxWidth: 1400) {
                ...GatsbyImageSharpFluid_withWebp
                presentationWidth
              }
            }
          }
        }
      }
    }
       
  }
`;
