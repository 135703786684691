import React, { Component } from 'react';
import { StaticQuery, graphql, Link } from 'gatsby';
import styled from '@emotion/styled';
import { color, breakpoints } from '../../../utils/style';
import DepositUserData from './DepositUserData';
import DepositPaymentData from './DepositPaymentData';

const Wrapper = styled('div')`
margin-bottom: 70px;
.top-step-tabs {
  display: flex;
  flex-flow: row wrap;
  max-width: 650px;
  margin: 0 auto 30px;
  position: relative;
  &::before {
    content: "";
    width: 150px;
    border-top: 2px solid ${color.peach};
    position: absolute;
    top: 20px;
    left: 50%;
    margin-left: -75px;
    opacity: 0.2;
  }
}
.top-step-tab {
  flex-basis: 50%;
  padding: 0 20px 10px;
  .text, .number {
    display: block;
    color: ${color.peach};
    text-align: center;
  }
  .number {
    font-size: 40px;
    font-weight: bold;
    padding-bottom: 5px;
    text-shadow: 0 0 16px ${color.peach};
    -webkit-text-stroke: 2px ${color.peach};
    -webkit-text-fill-color: ${color.transparent};
  }
}
&.user-data-mode {
  .payment-data-step {
    opacity: 0.3;
  }
}

&.payment-data-mode {
  .user-data-step {
    opacity: 0.3;
  }
}

@media (max-width: ${breakpoints.sm}) {
  .top-step-tabs {
    &::before {
      width: 50px;
      margin-left: -25px;
    }
    .text {
      font-size: 14px;
    }
    .number {
      text-shadow: 0 0 10px ${color.peach};
    }
  }
}

`;

export default class DepositParent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      view: 'userData',
      userName: '',
      auth: '',
      showUserData: true,
      showPaymentData: false,
      options: {}
    }
  }

  setUserName = (name, token) => {
    this.setState((prevState, props) => {
      return {
        view: prevState.view,
        userName: name,
        auth: token,
        showUserData: prevState.showUserData,
        showPaymentData: prevState.showPaymentData,
        options: prevState.options
      }
    });
  };

  changeView = (view, userData) => {
    this.setState((prevState, props) => {
      return {
        view: view,
        userName: prevState.userName,
        auth: prevState.auth,
        showUserData: !prevState.showUserData,
        showPaymentData: !prevState.showPaymentData,
        options: userData
      }
    });
  };

  handleVisualSwitch = () => {
    this.setState((prevState, props) => {
      return {
        view: prevState.view,
        showUserData: !prevState.showUserData,
        showPaymentData: !prevState.showPaymentData,
        options: prevState.options
      }
    });
  };

  render() {
    const wrapperClassName = this.state.showUserData ? 'user-data-mode' : 'payment-data-mode';
    return (
      <Wrapper className={wrapperClassName}>
        <div className="top-step-tabs">
          <div className="top-step-tab user-data-step">
            <span className="number">01</span>
            <span className="text">ข้อมูลบัญชีผู้เล่น</span>
          </div>
          <div className="top-step-tab payment-data-step">
            <span className="number">02</span>
            <span className="text">ข้อมูลการฝากเงิน</span>
          </div>
        </div>
        {this.state.showUserData &&
          <DepositUserData switchView={this.changeView} setUserName={this.setUserName} userName={this.state.userName} />
        }
        {this.state.showPaymentData &&
          <DepositPaymentData switchView={this.changeView} formData={this.state.options} token={this.state.auth}
                              switchVisual={this.handleVisualSwitch} codes={this.props.codes}/>
        }

      </Wrapper>

    );

  }
}